import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteEvent: {},
    isLoading: false,
   error : null
}

const deleteEventSlice = createSlice({
    name: 'deleteEvent',
    initialState,
    reducers:{
        deleteEventPending: (state) => {
            state.isLoadingEvents = true;
        },
        deleteEventSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.deleteEvent = payload;
            state.error = null;
        },
        deleteEventFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {deleteEventPending, deleteEventSuccess, deleteEventFail} = deleteEventSlice.actions

export default deleteEventSlice.reducer