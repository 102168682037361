import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addEvent: {},
    isLoading: false,
     error : null
}

const addEventSlice = createSlice({
    name: 'addEvent',
    initialState,
    reducers:{
        addEventPending: (state) => {
            state.isLoadingEvents = true;
        },
        addEventSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.addEvent = payload?.event;
            state.error = null;
        },
        addEventFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {addEventPending, addEventSuccess, addEventFail} = addEventSlice.actions

export default addEventSlice.reducer