import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getEvents: {},
    isLoadingEvents: false,
    errorGetEvents: ''
}

const getEventsSlice = createSlice({
    name: 'getEvents',
    initialState,
    reducers:{
        getEventsPending: (state) => {
            state.isLoadingEvents = true;
        },
        getEventsSuccess: (state, {payload}) => {
            state.isLoadingEvents = false;
            state.getEvents = payload.events;
            state.errorGetEvents = '';
        },
        getEventsFail: (state, {payload}) => {
            state.isLoadingEvents = false;
            state.errorGetEvents = payload;
        }
    }
})

export const {getEventsPending, getEventsSuccess, getEventsFail} = getEventsSlice.actions

export default getEventsSlice.reducer